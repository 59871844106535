import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import React from 'react';
import LegalArea from './legalPage.style';
const LegalPage = () => {
    return (
        <LegalArea id="LegalSection">
            <Container>
            <Heading as="h4" content="AVISO LEGAL" />
            <Box className="mt-12 text-left">
                <p className="mt-12 mb-10">SIRVENT PRODUCTES, con CIF  B60730256, dirección  Carrer Camí Antic de Vic, 17, 08520 Corró d'Avall, (Barcelona), no puede asumir ninguna responsabilidad derivada del uso incorrecto, inapropiado o ilícito de la información aparecida en las páginas de Internet de https://info.sirventproductes.com.</p>
                <p className="mt-12 mb-10">Con los límites establecidos en la ley, https://info.sirventproductes.com no asume ninguna responsabilidad derivada de la falta de veracidad, integridad, actualización y precisión de los datos o informaciones que se contienen en sus páginas de Internet.</p>
                <p className="mt-12 mb-10">Los contenidos e información no vinculan a https://info.sirventproductes.com ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.</p>
                <p className="mt-12 mb-10">Las páginas de Internet de https://info.sirventproductes.com pueden contener enlaces (links) a otras páginas de terceras partes que www.sirventproductes.com no puede controlar. Por lo tanto, www.sirventproductes.com no puede asumir responsabilidades por el contenido que pueda aparecer en páginas de terceros.</p>
                <p className="mt-12 mb-10">Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad exclusiva de https://info.sirventproductes.com o sus licenciantes. Cualquier acto de transmisión, distribución, cesión, reproducción, almacenamiento o comunicación pública total o parcial, debe contar con el consentimiento expreso de www.sirventproductes.com.</p>
                <p className="mt-12 mb-10">Asimismo, para acceder a algunos de los servicios que https://info.sirventproductes.com ofrece a través del website deberá proporcionar algunos datos de carácter personal. En cumplimiento de lo establecido en el Reglamento(UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos le informamos que, mediante la cumplimentación de los presentes formularios, sus datos personales quedarán incorporados y serán tratados en los ficheros de SIRVENT PRODUTES con el fin de poderle prestar y ofrecer nuestros servicios así como para informarle de las mejoras del sitio Web. Asimismo, le informamos de la posibilidad de que ejerza los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal, manera gratuita mediante email a info@sirventproductes.com o en la dirección Carrer Camí Antic de Vic, 17, 08520 Corró d'Avall, (Barcelona).</p>
            </Box>
          </Container>
        </LegalArea>
  );
};

export default LegalPage;
